import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelfViewerRoutingModule } from './self-viewer-routing.module';
import { SelfViewerComponent } from './self-viewer.component';
import { LocalStorageService } from './local-storage.service';
import { SharedModule } from '../_shared/shared.module';
import { TRImageViewerComponent } from '../_shared/components/tr-image-viewer';

@NgModule({
  declarations: [SelfViewerComponent],
  exports: [SelfViewerComponent],
  providers: [LocalStorageService],
  imports: [CommonModule, TRImageViewerComponent, SelfViewerRoutingModule, SharedModule],
})
export class SelfViewerModule {}
