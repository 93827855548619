import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Configs } from '../_shared/configs.model';
import { InboundDocument } from '../store/models/inbound-document.model';

import DocumentTypes = InboundDocument.DocumentTypes;
import { NotificationService } from '../_shared/notification/notification.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { FileService } from '../_api/file.service';
@Component({
  selector: 'app-self-viewer',
  templateUrl: './self-viewer.component.html',
  styleUrls: ['./self-viewer.component.scss'],
})
export class SelfViewerComponent implements OnInit, OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event): void {
    this.localStorageService.removeStorageItem('self-viewer');
    this.localStorageService.removeStorageItem('isOpen');
  }

  private subscription?: Subscription;

  safeUrl: SafeResourceUrl;
  imageUrl: string;

  isImg: boolean;

  data: any; // local storage

  constructor(
    private ntf: NotificationService,
    private sanitizer: DomSanitizer,
    private localStorageService: LocalStorageService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    this.subscription = this.localStorageService.storageChange$.subscribe((event: StorageEvent) => {
      if (event.key === 'self-viewer') {
        if (event.newValue) {
          this.data = JSON.parse(event.newValue);
          this.render();
        } else {
          this.data = null;
        }
      }
    });

    if (this.localStorageService.getStorageItem('self-viewer')) {
      this.data = JSON.parse(this.localStorageService.getStorageItem('self-viewer'));
      this.render();
    }
  }

  getExtension(url: string): string {
    const [urlWithoutQueryParams] = url.split('?');
    return urlWithoutQueryParams.slice(urlWithoutQueryParams.lastIndexOf('.') + 1);
  }

  render() {
    this.fileService.getResourceUrl(this.data).subscribe((url) => {
      if (!url) {
        this.isImg = null;
        this.safeUrl = null;

        setTimeout(() => {
          if (this.data.documentType !== DocumentTypes.email) {
            this.ntf.error('ntf.no_file_url');
          }
        });
      } else {
        const ext = this.getExtension(this.data.documentName)?.toLowerCase();
        // const ext = this.data.file.documentName.split('.')[1];
        this.isImg = Configs.COMMON_IMG_FORMATS.includes(ext);

        if (Configs.COMMON_IMG_FORMATS.includes(ext)) {
          this.isImg = Configs.COMMON_IMG_FORMATS.includes(ext);
          this.imageUrl = url;
        }

        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
